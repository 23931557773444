import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ContentContainer from "../components/ContentContainer";

const TOS = () => (
	<Layout>
		<SEO title="Terms of Service"/>
		<ContentContainer title="Terms of Service" half className="text-center">
			<p>TODO: add TOS</p>
		</ContentContainer>
	</Layout>
);

export default TOS;
